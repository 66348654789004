(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name wbAccount.wbCompany.filter:csnFileStatusClassName
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .filter('csnFileStatusClassName', csnFileStatusClassName);

  function csnFileStatusClassName() {
    return function (csnFile) {
      var classNames = [];

      switch (csnFile.status) {
        case 'success':
          classNames.push('md-accent zmdi-download');
          break;
        case 'failed':
          classNames.push('md-warn zmdi-close');
          break;
        case 'pending':
          classNames.push('zmdi-hourglass');
          break;
        default:
          classNames.push('status-? zmdi-help-circle');
          break;
      }

      return classNames.join(' ');
    };
  }
}());
